import React from 'react';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import logo from '../statics/ionomr-white.png'

/**
 * @description Company logo component, is clickable and will direct the user to the landing page
 * 
 * @returns {React.FC} ionomr logo component
 */
const IonomrLogo: React.FC = () => {
    return (
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Link to="/">
            <img src={logo} alt="logo" style={{ height: '50px', marginRight: '20px' }}/>
            </Link>
        </Box>
    );
};

export { IonomrLogo };
