import React, { useState } from 'react';
import { IconButton, Drawer, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import membranelogo from '../statics/membrane.jpg';


interface Props {
    loggedIn: boolean;
}

/**
 * @description three line menu in top left of header that is used by user to traverse the website
 * This component name is funny. I'm going to leave a trail of who has seen this message. Add your name! I hope you enjoy this future Legacy code :)
 * Sylvain Taghaoussi (OG author).
 * 
 * @param props hamburger props
 * @param props.loggedIn prop that indicates if the current user is logged in or not
 * 
 * @returns {React.FC} returns a react functional component that is used to traverse the website, contains a pop up with menu selection
 */
const Hamburger: React.FC<Props> = ({ loggedIn }) => {
    const [HamOpen, setHam] = useState<boolean>(false);
    const navigate = useNavigate();

    /**
     * @description handler function when user clicks on a page directory, constructs the path from the param and sets the pop up to false
     * 
     * @param path this is the path that the user wants to navigate to
     */
    const pageClicked = (path: string) => {
        setHam(false);
        navigate(path);
    }

    return (
        <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {if(loggedIn){setHam(!HamOpen)}else{console.log('not logged in')}}}
              sx={{ mr: 2 }}
            >
            <MenuIcon />
            </IconButton>
            <Drawer open={HamOpen} onClose={() => {setHam(false)}}>
              {
                <div style={{display:'flex', flexDirection:'column', width:'350px', justifyContent:'flex-start', alignItems:'flex-start'}}>
                  {/** Site Navigation Title */}
                  <div style={{width:'100%',backgroundColor: '#001833',}}>
                    <p style={{fontSize: 'calc(2rem)',color: 'white',fontFamily: 'Helvetica', margin:0, padding:'10px 20px'}}>Site Navigation</p>
                  </div>
                  
                  {/** Product Dashboard link */}
                  <div onClick={() => {pageClicked('/dashboard')}} style={{display: 'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center', cursor:'pointer', width:'100%'}}>
                    <img src={membranelogo} style={{width:'50px', height:'50px', marginLeft:'8px'}} alt=""/>
                    <p style={{fontSize:'calc(1.5rem)', color:'#383737', paddingLeft:'8px', fontFamily: 'Helvetica'}}>
                      Product Dashboard
                    </p>
                  </div>
                  <Divider flexItem orientation='horizontal' sx={{ bgcolor: 'rgba(0, 0, 0, 0.10)' }}/>
                  

                  {/** Product Dashboard link */}
                  <div onClick={() => {pageClicked('/we_testing')}} style={{display: 'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center', cursor:'pointer', width:'100%'}}>
                    <img src={membranelogo} style={{width:'50px', height:'50px', marginLeft:'8px'}} alt=""/>
                    <p style={{fontSize:'calc(1.5rem)', color:'#383737', paddingLeft:'8px', fontFamily: 'Helvetica'}}>
                      WE Testing
                    </p>
                  </div>

                </div>
                
                
              }

            </Drawer>
        </>
    );
};

export { Hamburger };
