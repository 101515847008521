import React from 'react';
import { Hamburger } from './Hamburger';
import { AccountManager } from './AccountManager';
import { IonomrLogo } from './IonomrLogo';
import { AppBar, Toolbar} from '@mui/material';
import { styled } from '@mui/system';

const StyledAppBar = styled(AppBar)({
    backgroundColor: '#001833',
    minHeight: '5vh',
});

interface Props {
    loggedIn: boolean;
    setLoggedIn: (loggedIn: boolean) => void;
}

/**
 * @description this component is the highlevel header whcih renders the hamburger site navigator, ionomr logo,  and account manager
 * 
 * @param props header props
 * @param props.loggedIn logged in state for user
 * @param props.setLoggedIn loggedin state setter for user
 * 
 * @returns {React.FC} returns header
 */
const AppHeader: React.FC<Props> = ({ loggedIn, setLoggedIn }) => {

    return (
        <StyledAppBar position="static">
          <Toolbar>
            <Hamburger loggedIn={loggedIn}/>
            <IonomrLogo />
            <AccountManager setLoggedIn={setLoggedIn} loggedIn={loggedIn}/>
          </Toolbar>
        </StyledAppBar>
    );
};

export { AppHeader };
