import React, { useState, useEffect } from 'react';
import axios from 'axios';



/**
 * @description useAuth function sends a request to the server to check the users Authentication status
 * 
 * @returns {{loggedIn: boolean, setLoggedIn: React.SetStateAction<boolean>}} returns a useState variable and setter for authentication status
 */
const useAuth = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    /**
     * @description async auth function that sends request to server to check users status
     * don't declare it in useEffect, don't want to redeclare a function everytime it needs to be called (inefficient)
     * Doesn't return everything just sets authentication status
     */
    const checkAuth = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/status`, {withCredentials: true});
            setLoggedIn(true);
        } catch (error) {
            setLoggedIn(false);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        checkAuth();
    }, []);
    return {loggedIn, setLoggedIn, loading};
}

export default useAuth;