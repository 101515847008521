import React, { useState } from 'react';
import { IconButton, Menu, MenuItem} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface Props {
    loggedIn: boolean;
    setLoggedIn: (loggedIn: boolean) => void;
}

/**
 * @description icon in the top right used to manage user account
 * Currently only allows user to logout, which redirects them to the login landing page
 *
 * @param props AccountManager props
 * @param props.setLoggedIn setter that sets the users authentication status to loggedin or loggedout (boolean)
 * @param props.loggedIn variable that indicates if user is logged in or not
 * 
 * @returns {React.FC} react component to the header which is used to manage users account, more will be added in the future
 */
const AccountManager: React.FC<Props> = ({ setLoggedIn, loggedIn }) => {
    const [showDropDown, setShow] = useState<HTMLElement | null>(null);
    const navigate = useNavigate()


    /**
     * @description function that handles user logging out, redirects them to login page.
     */
    const handleLogout = async () => {
        try{
          await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/logout`, {}, {withCredentials: true});
          setShow(null)
          setLoggedIn(false);
          navigate('/login');
        } catch (error) {
          console.error('Logout Failed', error);
        }
      };

    return (
        <div style={{display:'flex', flexDirection:'row'}}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => {loggedIn ? setShow(e.currentTarget):console.log("Not logged in, can't logout")}}
                color="inherit"
            >
            <AccountCircleIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={showDropDown}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                keepMounted
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={Boolean(showDropDown)}
                onClose={() => {setShow(null)}}
            >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </div>
    );
};

export { AccountManager };
