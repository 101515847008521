import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
    isAuthenticated: boolean;
    children: React.ReactNode;
}

/**
 * @description wrapper component that protects sensitive information and checks authentication status before rendering
 * 
 * @param props { isAuthenticated, children } - Object containing all component props:
 * @param props.isAuthenticated - Indicates the user's authentication status.
 * @param props.children - Components to render if authenticated.
 * @returns {React.ReactElement | null} Returns whatever component ProtectedRoute wrapped or redirects if not authenticated.
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAuthenticated, children }) => {
    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
}

export { ProtectedRoute }